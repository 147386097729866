import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  > h4 > a {
    cursor: pointer;
    &:hover {
      text-decoration: underline gray;
    }
  }
`;

const NotAuth = ({ onClick }) => (
  <Wrap>
    <Typography variant="h4">No estás autenticado, click <a onClick={onClick}>aquí</a> para autenticarte.</Typography>
  </Wrap>
);

export default NotAuth;
