import { createAsyncThunk } from "@reduxjs/toolkit";
import * as R from "ramda";
import http from "../../utils/http";
import { getData } from "../../utils/utils";

export const searchCustomers = createAsyncThunk(
  "customer/search",
  (keyword) => {
    return http
      .get(`/customers?take=1000&keyword=${keyword}`)
      .then(R.compose(getData, getData));
  }
);

export const createCustomer = createAsyncThunk(
  "customer/createCustomer",
  (data) => {
    return http.post("/customers", data).then(getData);
  }
);
