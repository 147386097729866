import { createSlice } from "@reduxjs/toolkit";
import * as R from "ramda";

const buildExtraReducers = R.reduce((reducers, [reducer, fulfilled]) => {
  return {
    ...reducers,
    [reducer.pending]: (state) => {
      state.isLoading = true;
      return state;
    },
    [reducer.fulfilled]: (state, action) => {
      state.isLoading = false;
      return fulfilled(state, action);
    },
    [reducer.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      return state;
    },
  };
}, {});

/**
 * Create context/slice
 * @param {*}
 *
 * { name, initialState, reducers, extraReducers }
 *
 * @returns Slice
 */
export const createContext = ({
  name,
  initialState,
  reducers,
  extraReducers,
}) => {
  return createSlice({
    name,
    reducers: {
      ...reducers,
      cleanError: (state) => {
        state.error = null;
      },
    },
    initialState: { isLoading: false, error: null, ...initialState },
    extraReducers: buildExtraReducers(extraReducers),
  });
};
