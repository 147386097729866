import { createAsyncThunk } from '@reduxjs/toolkit';
import * as R from 'ramda';
import http from '../../utils/http';
import { getData } from '../../utils/utils';

export const findAllOrganizationUnits = createAsyncThunk(
  'organizationUnit/findAll',
  (data) => {
    return http.get('/organization-units?take=1000')
      .then(R.compose(getData, getData));
  }
);
