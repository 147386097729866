import React, { useState, useEffect } from "react";
import { Form } from "react-final-form";

import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import CloseTurnForm from "../../Forms/CloseTurnForm";
import { roundNumberValue } from "../../../utils/number";

const CloseTurnModal = ({
  open = false,
  turnSalesDetail = {},
  loading = false,
  onClose,
  onSubmit,
}) => {
  const [turnSalesTotal, setTurnSalesTotal] = useState(
    turnSalesDetail.base + turnSalesDetail.effectiveSales
  );
  const [difference, setDifference] = useState(turnSalesTotal);

  const initialValues = {
    effectiveIncome: 0,
    effectiveExpensive: 0,
    cashTotal: 0,
    description: "",
  };

  const onFormChangeHandle = (values) => {
    const cashTotal = values["cashTotal"] | 0;
    const effectiveIncome = values["effectiveIncome"] | 0;
    const effectiveExpensive = values["effectiveExpensive"] | 0;
    // TODO: validate the result mustn't be negative
    
    const result =
    roundNumberValue(turnSalesDetail.base +
      turnSalesDetail.effectiveSales +
      effectiveIncome -
      effectiveExpensive);
    setTurnSalesTotal(result);
    setDifference(roundNumberValue(result - cashTotal));
  };

  useEffect(() => {
    setTurnSalesTotal(turnSalesDetail.base + turnSalesDetail.effectiveSales);
    setDifference(turnSalesDetail.base + turnSalesDetail.effectiveSales);
  }, [turnSalesDetail]);

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <Form onSubmit={onSubmit} initialValues={initialValues}>
        {({ handleSubmit, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Typography color="primary" variant="h5">
                Cerrar Turno
              </Typography>
              <DialogContent dividers>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>VALOR ESPERADO EN CAJA</Typography>
                  <Typography style={{ fontSize: "25px" }}>
                    ${turnSalesTotal}
                  </Typography>
                </div>
                <CloseTurnForm
                  difference={difference}
                  values={values}
                  turnSalesDetail={turnSalesDetail}
                  onSubmit={onSubmit}
                  onFormChange={onFormChangeHandle}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={loading}
                  variant="contained"
                  color="secondary"
                  style={{ marginRight: "1em" }}
                  onClick={onClose}
                >
                  Cancelar
                </Button>
                <Button
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  type="submit"
                  // onClick={onOpenTurn}
                >
                  Cerrar Turno
                  {loading && <CircularProgress color="secondary" size={20} />}
                </Button>
              </DialogActions>
            </form>
          );
        }}
      </Form>
    </Dialog>
  );
};

export default CloseTurnModal;
