import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';

import { ModalWrap, Wrap, Header, Content, Footer } from './components';

const Modal = ({
  children,
  open = false,
  onClose,
  header,
  footer,
  width
}) => (
    <ModalWrap
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      onClose={onClose}>
      <Wrap width={width}>
        {header ? <Header onClose={onClose}>{header}</Header> : null}
        <Content>{children}</Content>
        {footer ? <Footer>{footer}</Footer> : null}
      </Wrap>
    </ModalWrap>
  );

export default Modal;
