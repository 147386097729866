import { createAsyncThunk } from "@reduxjs/toolkit";
import * as R from "ramda";
import http from "../../utils/http";
import { getData } from "../../utils/utils";

export const findAllAccountingAccounts = createAsyncThunk(
  "accountingAccount/findAll",
  (data) => {
    return http
      .get("/accounting-accounts?take=1000")
      .then(R.compose(getData, getData));
  }
);
