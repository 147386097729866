import { createContext } from "../../core/store/createContext";
import { createExtraReducer } from "../../core/store/createExtraReducer";

import { searchCustomers, createCustomer } from "./thunks";

export default createContext({
  name: "customers",
  initialState: {
    customers: [],
  },
  reducers: {
    clearCustomers(state) {
      state.customers = [];
    },
    cleanError: (state) => {
      state.error = null;
    },
  },
  extraReducers: [
    createExtraReducer(searchCustomers, (state, action) => {
      state.isLoading = false;
      state.customers = action.payload;
      return state;
    }),
    createExtraReducer(createCustomer, (state, action) => {
      state.isLoading = false;
      // console.log("action.payload", action.payload);
      // state.customers = action.payload;
      return state;
    }),
  ],
});
