import * as R from 'ramda';
import { isNotEmptyOrNil, removeUndefined } from '../../utils/utils';

export const selectBoxes = state => state.boxes.boxes;

export const selectSettingBox = R.compose(
  removeUndefined,
  R.ifElse(
    isNotEmptyOrNil,
    R.applySpec({
      openBank: R.path(['openBank', 'id']),
      closeBank: R.path(['closeBank', 'id']),
      transfer: R.path(['defaultTransferBank', 'id']),
      debit: R.path(['defaultDebitBank', 'id']),
      credit: R.path(['defaultCreditBank', 'id']),
      effective: R.path(['defaultEffectiveBank', 'id']),
      accountingAccount: R.path(['accountingAccount', 'id']),
      organizationUnitId: R.prop('organizationUnitId'),
    }),
    R.always({})
  ),
  R.path(['boxes', 'settingBox'])
);

export const selectIsLoading = state => state.boxes.isLoading;

export const selectBoxesForSelect = R.compose(
  R.map(R.applySpec({
    value: R.prop('id'), label: R.prop('name')
  })),
  R.defaultTo([]),
  selectBoxes
);

export const selectIsLoadingBoxSetting = state =>
  state.boxes.isLoading ||
  state.banks.isLoading ||
  state.organizationUnits.isLoading ||
  state.accountingAccounts.isLoading;
