import ModalMU from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';

export const ModalWrap = styled(ModalMU)`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 5em;
`;

export const Wrap = styled.div`
  width: ${props => props.width ? props.width : '50em'};
  background-color: #FFFFFF;
  border-radius: 0.3em;
  outline: none !important;
`;

const HeaderBase = ({ className, children, onClose }) => (
  <div className={className}>
    <div className='content'>{children}</div>
    <div className='close-icon'>
      <IconButton component="span" onClick={onClose}><CloseIcon /></IconButton>
    </div>
  </div>
);

export const Header = styled(HeaderBase)`
  display: flex;
  border-bottom: 1px solid gray;
  padding: 0 1em 0 1em;
  align-items: center;
  > .content {
    flex: 1;
  }
  > .close-icon {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    > span {
      padding-right: 0 !important;
    }
  }
`;

export const Content = styled.div`
  padding: 1em;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid gray;
  padding: 1em;
`;
