import React, { useCallback, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useSelector, useDispatch } from "react-redux";
// import { unwrapResult } from '@reduxjs/toolkit';

import { setItem } from "../../utils/localStorage";
import OpenTurnModal from "../Modals/OpenTurnModal";
import CloseTurnModal from "../Modals/CloseTurnModal";
import { findAllBoxes, findSettingBox } from "../../store/box/thunks";
import {
  createTurn,
  fetchTurnSalesDetail,
  closeOpenTurn,
  fetchCurrentUserTurn
} from "../../store/turn/thunks";
import {
  selectBoxesForSelect,
  selectIsLoading as selectBoxIsLoading,
} from "../../store/box/selectors";
import {
  selectIsLoading as selectTurnIsLoading,
  selectIsThereAnyTurnOpened,
  selectTurnSalesDetail,
} from "../../store/turn/selectors";

const WorkerInfo = ({ companyName, workerEmail, onClose, userId }) => {
  const dispatch = useDispatch();
  const boxes = useSelector(selectBoxesForSelect);
  const boxIsLoading = useSelector(selectBoxIsLoading);
  const turnIsLoading = useSelector(selectTurnIsLoading);
  const isThereAnyTurnOpened = useSelector(selectIsThereAnyTurnOpened);
  const turnSalesDetail = useSelector(selectTurnSalesDetail);
  const [isOpenTurnModalOpen, setIsOpenTurnModalOpen] = useState(false);
  const [isCloseTurnModalOpen, setIsCloseTurnModalOpen] = useState(false);

  const onOpenTurnHandle = useCallback(() => {
    dispatch(findAllBoxes());
    setIsOpenTurnModalOpen(true);
  }, [dispatch]);

  const onCloseTurnHandle = useCallback(() => {
    dispatch(fetchTurnSalesDetail());
    setIsCloseTurnModalOpen(true);
  }, [dispatch]);

  const onOpenTurnSubmitHandle = useCallback(
    async (values) => {
      const data = { ...values, user: userId };
      await dispatch(createTurn(data));
      await dispatch(fetchCurrentUserTurn());
      await dispatch(findSettingBox())
      // .then(unwrapResult);
      setItem("BOX_ID_SELECTED", data.box);
      setIsOpenTurnModalOpen(false);
      onClose();
    },
    [dispatch, onClose, userId]
  );

  const onCloseTurnSubmitHandle = useCallback(
    async (values) => {
      await dispatch(closeOpenTurn({ ...values, ...turnSalesDetail }));
      setIsCloseTurnModalOpen(false);
      onClose();
    },
    [dispatch, turnSalesDetail, onClose]
  );

  return (
    <div style={{ padding: "1em" }}>
      <Typography align="center" style={{ fontWeight: "bold" }}>
        {companyName}
      </Typography>
      <Typography align="center">{workerEmail}</Typography>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "1em" }}
      >
        <Button
          fullWidth
          disabled={boxIsLoading || turnIsLoading}
          size="small"
          variant="contained"
          color="primary"
          onClick={isThereAnyTurnOpened ? onCloseTurnHandle : onOpenTurnHandle}
        >
          {isThereAnyTurnOpened ? "Cerrar turno" : "Abrir turno"}
          {(boxIsLoading || turnIsLoading) && (
            <CircularProgress color="secondary" size={20} />
          )}
        </Button>
      </div>
      <OpenTurnModal
        open={isOpenTurnModalOpen}
        boxes={boxes}
        loading={turnIsLoading}
        onSubmit={onOpenTurnSubmitHandle}
        onClose={() => setIsOpenTurnModalOpen(false)}
      />
      {turnSalesDetail && (
        <CloseTurnModal
          open={isCloseTurnModalOpen && !turnIsLoading}
          onSubmit={onCloseTurnSubmitHandle}
          turnSalesDetail={turnSalesDetail}
          onClose={() => setIsCloseTurnModalOpen(false)}
        />
      )}
    </div>
  );
};

export default WorkerInfo;
