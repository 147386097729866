import * as R from "ramda";

export const isLoadingSelector = R.compose(
  R.any((value) => value === true),
  R.map(R.prop("isLoading")),
  R.values
);

export const extractErrorsSelector = R.compose(
  R.map(R.path(["error", "message"])),
  R.filter(R.compose(R.not, R.isNil, R.prop("error"))),
  R.values
);
