import * as React from 'react';
import { Formik } from 'formik';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

const Actions = ({ submitForm, isSubmitting, showActions = true, buttonSubmitRef }) => (
  <>
    {isSubmitting && <LinearProgress />}
    <div style={{ display: showActions ? 'flex' : 'none', justifyContent: 'flex-end', padding: '1em 0 1em 0' }}>
      <Button
        variant="contained"
        color="secondary"
        disabled={isSubmitting}
        style={{ marginRight: '1em' }}>
        Cancelar
      </Button>
      <Button
        ref={buttonSubmitRef}
        variant="contained"
        color="primary"
        disabled={isSubmitting}
        onClick={submitForm}>
        Guardar
      </Button>
    </div>
  </>
);

const Form = (props) => {
  const { onSubmit, initialValues, validate, children, showActions, buttonSubmitRef, validationSchema } = props;

  const onSubmitHandle = (values, options) => {
    onSubmit(values, options);
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandle}>
      {(props) => {
        return (
          <>
            {children}
            <Actions
              showActions={showActions}
              buttonSubmitRef={buttonSubmitRef}
              {...props}
            />
          </>
        );
      }}
    </Formik>
  );
}

export default Form;
