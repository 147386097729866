import axios from "axios";
import * as R from "ramda";
import { getItem } from "./localStorage";

const client = axios.create({
  // TODO: define this as env var
  // baseURL: "https://default.api.addapptables.com/api",
  baseURL: `${process.env.REACT_APP_DEFAULT_API_URL}/api`,
  timeout: 20000,
  headers: {
    Authorization: `Bearer ${getItem("TOKEN")}`,
  },
});

const clientBlob = axios.create({
  // TODO: define this as env var
  // baseURL: "https://default.api.addapptables.com/api",
  baseURL: `${process.env.REACT_APP_DEFAULT_API_URL}/api`,
  headers: {
    Authorization: `Bearer ${getItem("TOKEN")}`,
  },
  responseType: 'blob'
});

const getStatusCode = R.path(["response", "data", "statusCode"]);

const success = R.identity;

const transformErrorMessage = R.compose(
  R.path(["response", "data", "message"])
);

const fail = R.compose(
  (error) => Promise.reject(error),
  R.applySpec({
    message: transformErrorMessage,
    name: R.always("Error"),
    stack: R.prop("message"),
  }),
  (error) => {
    // FIXME: please..... FIX THIS :'(
    if ([401].includes(getStatusCode(error))) {
      window.location = `${getItem("ANGULAR_CLIENT_URL")}/admin/invoices`;
    }

    return error;
  }
);

client.interceptors.response.use(success, fail);

clientBlob.interceptors.response.use(success, fail);

export default client;

export function createClientBlob() {
  return clientBlob;
}
