import React from "react";
import {
  makeStyles,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const useStyles = makeStyles(() => ({
  IconContent: {
    display: "flex",
    justifyContent: "center",
    padding: "0.5rem 0",
    "& > svg": {
      fontSize: "6rem",
    },
  },
}));

export const ErrorModal = ({ open, errors, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <div className={classes.IconContent}>
        <ErrorOutlineIcon color="error" />
      </div>
      <DialogContent dividers>
        <ul>
          {errors.map((error, index) => (
            <li key={`error_item_${index}`}>
              <Typography gutterBottom>{error}</Typography>
            </li>
          ))}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
