import { useState, useEffect } from "react";
import http from "../../utils/http";
import { setItem, getItem } from "../../utils/localStorage";
import { getData } from "../../utils/utils";

const setMultiTenantUrl = (company, setAngularClientUrl) => {
  const isEnableMultiTenant =
    process.env.REACT_APP_IS_ENABLE_MULTI_TENANT === "true";
  if (company?.tenant && isEnableMultiTenant) {
    const url = process.env.REACT_APP_API_URL.replace(
      "{TENANT}",
      company.tenant.subDomain
    );
    http.defaults.baseURL = `${url}/api`;
    const clientUrl = process.env.REACT_APP_ANGULAR_URL.replace(
      "{TENANT}",
      company.tenant.subDomain
    );
    setAngularClientUrl(clientUrl);
  } else {
    setAngularClientUrl(process.env.REACT_APP_ANGULAR_URL);
  }
};


const refreshTokenPost = (data) => {
  return http.post("/oauth2/refresh_token", data)
  .then(getData)
  .then((data) => {
    setItem("TOKEN", data.accessToken);
    http.defaults.headers.Authorization = `Bearer ${getItem("TOKEN")}`;
    return data;
  });
};

const useFetchCompanyInfoHook = ({
  token = null,
  setAuth,
  setAngularClientUrl,
}) => {
  const [state, setState] = useState({
    loading: true,
    company: null,
    error: null,
  });

  if (token) setItem("TOKEN", token);

  useEffect(() => {
    http.defaults.headers.Authorization = `Bearer ${getItem("TOKEN")}`;

    http
      .get("/auth/login-information")
      .then(getData)
      .then((company) => {
        setState({ company, loading: false, error: null });
        if (company.user) {
          setAuth(true);
        }
        setMultiTenantUrl(company, setAngularClientUrl);
      })
      .catch((error) => {
        setMultiTenantUrl(null, setAngularClientUrl);
        setState({ company: null, loading: false, error: error });
        setAuth(false);
      });
  }, [setAuth, setAngularClientUrl]);

  return { ...state, setState };
};

export default useFetchCompanyInfoHook;

export { refreshTokenPost };
