import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

import "./App.css";
import Root from "./containers/Root";
import store from "./store/store";
import { AuthProvider } from "./contexts/auth";
import { AppProvider } from "./contexts/app";

const theme = createTheme({
  spacing: 4,
  palette: {
    primary: {
      main: "#00695c",
    },
    secondary: {
      main: "#ad1457",
    },
  },
});

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Provider store={store}>
          <MuiThemeProvider theme={theme}>
            <AppProvider>
              <Root />
            </AppProvider>
          </MuiThemeProvider>
        </Provider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
