import InboxIcon from '@material-ui/icons/MoveToInbox';

export const items = [
  {
    label: 'Facturar',
    path: '/invoices',
    icon: <InboxIcon />
  },
  {
    label: 'Ventas',
    path: '/sales',
    icon: <InboxIcon />
  },
  {
    label: 'Turnos',
    path: '/turns',
    icon: <InboxIcon />
  },
  // {
  //   label: 'Returns',
  //   path: '/returns',
  //   icon: <InboxIcon />
  // },
  // {
  //   label: 'Ajustes',
  //   path: '/settings',
  //   icon: <InboxIcon />
  // }
];

export default items;
