import { createAsyncThunk } from '@reduxjs/toolkit';
import * as R from 'ramda';
import http from '../../utils/http';
import { getData } from '../../utils/utils';
import { getItem } from '../../utils/localStorage';

export const findAllBoxes = createAsyncThunk(
  'box/findAll',
  () => http.get('/boxes?take=1000')
    .then(R.compose(getData, getData))
);

export const findBoxById = createAsyncThunk(
  'box/findById',
  (id) => http.get(`/boxes/${id}`)
    .then(getData)
);

export const findSettingBox = createAsyncThunk(
  'box/findSettingBox',
  () => {
    // TODO: setting box id must come from cookie/localstorage
    const id = getItem('BOX_ID_SELECTED');
    return http.get(`/boxes/${id}?defaultEffectiveBank=true&openBank=true&closeBank=true&defaultTransferBank=true&defaultDebitBank=true&defaultCreditBank=true&accountingAccount=true`)
      .then(getData)
  }
);

export const updateBox = createAsyncThunk(
  'box/update',
  (data) => http.patch(`/boxes/${data.id}`, data)
    .then(getData)
);
