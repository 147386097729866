import { createAsyncThunk } from '@reduxjs/toolkit';
import * as R from 'ramda';
import http from '../../utils/http';
import { getData } from '../../utils/utils';

export const findAllBank = createAsyncThunk(
  'bank/findAll',
  (data) => {
    return http.get('/banks?take=1000')
      .then(R.compose(getData, getData));
  }
);
