import React, { useState } from 'react';

export const HeaderContext = React.createContext({
  headerName: '',
  setHeaderName: () => { },
});

export const HeaderProvider = ({ children }) => {
  const [headerName, setHeaderName] = useState('Facturar');

  const context = {
    setHeaderName,
    headerName,
  };

  return (
    <HeaderContext.Provider value={context}>
      {children}
    </HeaderContext.Provider>
  );
};
