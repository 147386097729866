import { createAsyncThunk } from "@reduxjs/toolkit";
import * as R from "ramda";
import http from "../../utils/http";
import { getItem } from "../../utils/localStorage";

export const createTurn = createAsyncThunk("turns/create", async (data) => {
  return await http
    .post("/turns", data)
    .then(R.prop("data"))
    .catch((error) => {
      console.error("error", error);
      throw error;
    });
});

export const closeOpenTurn = createAsyncThunk("turns/closeOpen", (data) => {
  const turn = getItem("TURN_OPENED");
  return http.post(`/turns/${turn.id}/close`, data).then(R.prop("data"));
});

export const fetchTurnSalesDetail = createAsyncThunk(
  "turns/salesDetail",
  () => {
    const turn = getItem("TURN_OPENED");
    return http.get(`/turns/${turn.id}/sales-detail`).then(R.prop("data"));
  }
);

export const fetchTurns = createAsyncThunk("turns/fetch", () => {
  return http
    .get(`/turns?take=500&orderBy=consecutive&order=DESC`)
    .then(R.prop("data"));
});

export const fetchTurnById = createAsyncThunk(
  "turns/fetch-on-by-id",
  (turnId) => {
    return http
      .get(`/turns/${turnId}?calculateValues=true`)
      .then(R.prop("data"));
  }
);

export const fetchCurrentUserTurn = createAsyncThunk(
  "turns/user/current-open-turn",
  () => {
    return http.get("/turns/user/current-open-turn").then(R.prop("data"));
  }
);
