import {
  createTurn,
  closeOpenTurn,
  fetchTurnSalesDetail,
  fetchTurns,
  fetchCurrentUserTurn,
} from "./thunks";
import { setItem, removeItem } from "../../utils/localStorage";

import { createContext } from "../../core/store/createContext";
import { createExtraReducer } from "../../core/store/createExtraReducer";

export default createContext({
  name: "turns",
  initialState: {
    turns: [],
    turnOpened: false,
    turnSalesDetail: null,
    turnsPaginated: {
      data: [],
      total: 0,
    },
  },
  reducers: {},
  extraReducers: [
    createExtraReducer(createTurn, (state, action) => {
      state.turnOpened = action.payload;
      setItem("TURN_OPENED", action.payload);
      return state;
    }),
    createExtraReducer(fetchCurrentUserTurn, (state, action) => {
      state.turnOpened = action.payload;
      if (action.payload) {
        setItem("TURN_OPENED", action.payload);
        // TODO: look for a new place to put this logic
        setItem("BOX_ID_SELECTED", action.payload?.boxId);
      } else {
        removeItem("TURN_OPENED");
      }
      return state;
    }),
    createExtraReducer(closeOpenTurn, (state, action) => {
      state.turnOpened = null;
      state.turnSalesDetail = null;
      removeItem("TURN_OPENED");
      removeItem("BOX_ID_SELECTED");
      return state;
    }),
    createExtraReducer(fetchTurnSalesDetail, (state, action) => {
      state.turnSalesDetail = action.payload;
      return state;
    }),
    createExtraReducer(fetchTurns, (state, action) => {
      state.turnsPaginated = action.payload;
      return state;
    }),
  ],
});
