import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";

import LeftMenu from "../LeftMenu";
import { HeaderContext } from "../../contexts/header";

// TODO: change this by component-styled
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
}));

const Header = () => {
  const classes = useStyles();
  const [isLeftMenuOpen, setIsLeftMenuOpen] = useState(false);
  const { headerName } = useContext(HeaderContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const onOpenSettingsHandle = (event) => setAnchorEl(event.currentTarget);

  const onCloseSettingsHandle = () => setAnchorEl(null);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="primary">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => setIsLeftMenuOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {headerName}
          </Typography>
          <IconButton
            onClick={onOpenSettingsHandle}
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={onCloseSettingsHandle}
          >
            <MenuItem onClick={onCloseSettingsHandle}>
              Cerrar turno (forzar)
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <LeftMenu
        open={isLeftMenuOpen}
        onOpen={() => setIsLeftMenuOpen(true)}
        onClose={() => setIsLeftMenuOpen(false)}
      />
    </div>
  );
};

export default Header;
