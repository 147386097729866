import React, { useContext } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import Item from './Item';
import WorkerInfo from './WorkerInfo';
import { items } from './config';

import { AuthContext } from '../../contexts/auth';

const LeftMenu = ({ open, onClose, onOpen }) => {
  const { company } = useContext(AuthContext);

  return (
    <SwipeableDrawer
      anchor='left'
      open={open}
      onClose={onClose}
      onOpen={onOpen}>
      <div style={{ width: 250 }}>
        <WorkerInfo
          companyName={company.tenant.name}
          workerEmail={company.user.emailAddress}
          userId={company.user.id}
          onClose={onClose}
        />
        <Divider />
        <List>
          {items.map((item, index) => (
            <Item
              key={`menu-item-${index}`}
              onClick={onClose}
              {...item}
            />
          ))}
        </List>
      </div>
    </SwipeableDrawer>
  );
}

export default LeftMenu;
