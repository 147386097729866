import * as Yup from 'yup';

export const initialValues = {
  base: '',
  box: '',
};

export const schema = Yup.object().shape({
  base: Yup.number()
    .required('Requerido'),
  box: Yup.string()
    .uuid()
    .required('Requerido'),
});
