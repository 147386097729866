import React, { lazy, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import Header from '../../components/Header';
import { HeaderProvider } from '../../contexts/header';

const Invoice = lazy(() => import('../Invoice'));
const Sale = lazy(() => import('../Sale'));
const Turn = lazy(() => import('../Turn'));
const Returns = lazy(() => import('../Returns'));
const Settings = lazy(() => import('../Settings'));

const Root = () => {
  return (
    <>
      <HeaderProvider>
        <Header />
      </HeaderProvider>
      <div style={{ height: 'calc(100% - 4em' }}>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Navigate to="/invoices" />} />
            <Route path='/invoices' exact element={<Invoice></Invoice>} />
            <Route path='/sales' element={<Sale></Sale>} />
            <Route path='/turns' element={<Turn></Turn>} />
            <Route path='/returns' element={<Returns></Returns>} />
            <Route path='/settings' element={<Settings></Settings>} />
          </Routes>
        </Suspense>
      </div>
    </>
  );
}

export default Root;
