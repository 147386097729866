import React, { useEffect } from "react";
import { makeStyles, LinearProgress } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { ErrorModal } from "../../components/Modals/ErrorModal";
import store from "../../store/store";
import { fetchCurrentUserTurn } from "../../store/turn/thunks";

import {
  isLoadingSelector,
  extractErrorsSelector,
} from "../../store/global/selectors";

const useStyles = makeStyles(() => ({
  wrap: {
    position: "fixed",
    width: "100%",
    height: "100%",
    background: "#80808073",
    zIndex: 99,
  },
}));

export const AppContext = React.createContext({
  isLoading: false,
  errors: [],
});

export const AppProvider = ({ children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);
  const errors = useSelector(extractErrorsSelector);

  const context = {
    isLoading,
    errors,
  };

  const onCloseHandle = () => {
    const globalState = store.getState();
    Object.keys(globalState).forEach((key) => {
      const error = globalState[key]?.error;
      if (error) dispatch({ type: `${key}/cleanError`, payload: {} });
    });
  };

  useEffect(() => {
    // if (!getItem("TURN_OPENED")) {
    dispatch(fetchCurrentUserTurn());
    // }
  }, [dispatch]);

  return (
    <AppContext.Provider value={context}>
      {isLoading && (
        <>
          <LinearProgress />
          <div className={classes.wrap}></div>
        </>
      )}
      {children}
      <ErrorModal
        errors={errors}
        open={errors?.length > 0}
        onClose={onCloseHandle}
      />
    </AppContext.Provider>
  );
};
