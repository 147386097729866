import React from 'react';

import Form, { TextField, Select } from '../../Form';
import { initialValues, schema } from './schema';

const OpenTurnForm = ({ buttonSubmitRef, boxes, onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    initialValues={initialValues}
    validationSchema={schema}
    showActions={false}
    buttonSubmitRef={buttonSubmitRef}>
    <div style={{ padding: '1em 1em 2em 1em' }}>
      <TextField
        fullWidth
        required
        type="number"
        name="base"
        label="Base Inicial"
      />
      <Select
        fullWidth
        required
        name="box"
        label="Caja"
        items={boxes}
      />
    </div>
  </Form>
);

export default OpenTurnForm;
