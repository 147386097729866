import * as R from 'ramda';

export const getData = R.prop('data');

export const getQueryParamsFromObject = (query) => Object
  .entries(query)
  .map(([key, value]) => `${key}=${value}`)
  .join('&');

export const onEnterHandle = (cb) => (event) => {
  if (event.keyCode === 13) {
    cb();
    event.preventDefault();
  }
}

export const isEmptyOrNil = R.either(R.isNil, R.isEmpty);

export const isNotEmptyOrNil = R.compose(R.not, isEmptyOrNil);

export const removeUndefined = R.filter(isNotEmptyOrNil);
