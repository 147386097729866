import React, { useCallback, useRef } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import Modal from '../../Modal';
import OpenTurnForm from '../../Forms/OpenTurnForm';

const Title = () => (
  <Typography color="primary" variant="h5">
    Abrir Turno
  </Typography>
);

const Actions = ({ onClose, onOpenTurn, loading }) => (
  <>
    <Button
      disabled={loading}
      variant="contained"
      color="secondary"
      style={{ marginRight: '1em' }}
      onClick={onClose}>
      Cancelar
    </Button>
    <Button
      disabled={loading}
      variant="contained"
      color="primary"
      onClick={onOpenTurn}>
      Abrir Turno
      {loading && <CircularProgress color="secondary" size={20} />}
    </Button>
  </>
)

const OpenTurnModal = ({ open = false, boxes = [], loading = false, onClose, onSubmit }) => {
  const formSubmitButtonRef = useRef(null);

  const onOpenTurnHandle = useCallback(() => {
    formSubmitButtonRef.current.click();
  }, []);

  return (
    <Modal
      open={open}
      width={'30em'}
      onClose={onClose}
      header={<Title />}
      footer={<Actions
        loading={loading}
        onClose={onClose}
        onOpenTurn={onOpenTurnHandle}
      />}>
      <OpenTurnForm
        boxes={boxes}
        onSubmit={onSubmit}
        buttonSubmitRef={formSubmitButtonRef}
      />
    </Modal>
  );
}

export default OpenTurnModal;
