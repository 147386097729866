import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { HeaderContext } from '../../contexts/header';

const goPath = ({ path, label, history, onClick, setHeaderName }) => () => {
  history(path);
  setHeaderName(label);
  (typeof onClick === 'function') && onClick();
};

const Item = ({ label, path, icon, onClick }) => {
  const history = useNavigate();
  const { setHeaderName } = useContext(HeaderContext);

  return (
    <ListItem button onClick={goPath({ history, path, label, onClick, setHeaderName })}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={label} />
    </ListItem>
  );
}

export default Item;
