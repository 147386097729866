import { createAsyncThunk } from '@reduxjs/toolkit';
import * as R from 'ramda';
import http from '../../utils/http';
import { getData, getQueryParamsFromObject } from '../../utils/utils';

export const searchItems = createAsyncThunk(
  'items/search',
  // TODO: create an endpoint to get products by searching
  (query = {}) => http.get(`/items?${getQueryParamsFromObject({ ...query, take: 24 })}&tax=true&itemDiscountRules=true`)
    // TODO: improve this
    .then(R.compose(getData, getData))
);
