import React from 'react';
import * as R from 'ramda';
import { fieldToSelect } from 'formik-mui';
import { Field } from 'formik';
import SelectMaterial from "@material-ui/core/Select";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";

const SelectCustom = (props) => {
  const selectProps = { ...fieldToSelect(props) }
  const getError = R.path(['form', 'errors', selectProps.name]);
  const getTouched = R.path(['form', 'touched', selectProps.name]);
  const error = getError(props);
  const touched = getTouched(props);

  return (
    <FormControl error={error && touched} fullWidth={selectProps.fullWidth}>
      <InputLabel required={selectProps.required}>{selectProps.label}</InputLabel>
      <SelectMaterial {...selectProps} value={selectProps.value || ''}>
        {(props.items || []).map(({ value, label }, index) =>
          (<MenuItem key={`select-${value}-${label}-${index}`} value={value}>{label}</MenuItem>))}
      </SelectMaterial>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};

const Select = (props) => (
  <Field
    {...props}
    component={SelectCustom}
  />
);

export default Select;
