import { createAsyncThunk } from "@reduxjs/toolkit";
import * as R from "ramda";
import http from "../../utils/http";
import { getItem } from "../../utils/localStorage";

const getPayments = ({ payments, settingBox }) => {
  const bulkPayments = [];

  for (const [paymentType, value] of Object.entries(payments)) {
    if (!value || value === 0) continue;

    const payment = {
      value,
      paymentType,
      type: "income",
      // return?: number;
      accountingAccounts: [
        {
          accountingAccount: settingBox.accountingAccount,
          value,
        },
      ],
      bank: settingBox[paymentType],
      organizationUnit: settingBox.organizationUnitId,
    };

    bulkPayments.push(payment);
  }

  if(bulkPayments.length === 0) {
    bulkPayments.push({
      value: 0,
      paymentType: 'effective',
      type: "income",
      // return?: number;
      accountingAccounts: [
        {
          accountingAccount: settingBox.accountingAccount,
          value: 0,
        },
      ],
      bank: settingBox['effective'],
      organizationUnit: settingBox.organizationUnitId,
    })
  }

  return bulkPayments;
};

export const createInvoice = createAsyncThunk(
  "invoices/create",
  async ({ invoice, payments, settingBox }) => {
    invoice.payments = getPayments({
      settingBox,
      payments,
    });

    const invoiceCreated = await http
      .post("/invoices", invoice)
      .then(R.prop("data"));

    return invoiceCreated;
  }
);

export const fetchInvoices = createAsyncThunk("invoices/fetchAll", () => {
  const turn = getItem("TURN_OPENED");
  return http
    .get(
      `/invoices?take=500&turnId=${turn.id}&invoiceItems=true&customer=true&payment=true`
    )
    .then(R.prop("data"));
});

export const getInvoiceById = createAsyncThunk("invoices/getInvoiceById", (id) => {
  return http
    .get(
      `/invoices/${id}?payment=true&taxes=true&invoiceItems=true&customer=true`
    )
    .then(R.prop("data"));
});
