import { configureStore } from "@reduxjs/toolkit";

import itemReducer from "./item/reducers";
import invoiceReducer from "./invoice/reducers";
import turnReducer from "./turn/reducers";
import bankReducer from "./bank/reducers";
import boxReducer from "./box/reducers";
import organizationUnitReducer from "./organization-unit/reducers";
import accountingAccountReducer from "./accounting-account/reducers";
import customerReducer from "./customer/reducers";

export default configureStore({
  reducer: {
    items: itemReducer,
    invoices: invoiceReducer,
    turns: turnReducer,
    banks: bankReducer,
    boxes: boxReducer,
    organizationUnits: organizationUnitReducer,
    accountingAccounts: accountingAccountReducer,
    customers: customerReducer,
  },
});
