import { createInvoice, fetchInvoices, getInvoiceById } from "./thunks";

import { createContext } from "../../core/store/createContext";
import { createExtraReducer } from "../../core/store/createExtraReducer";
import { closeOpenTurn } from "../turn/thunks.js";

export default createContext({
  name: "invoices",
  initialState: {
    invoices: [],
    invoicesPaginated: {
      data: [],
      total: 0,
    },
    invoice: undefined
  },
  reducers: {},
  extraReducers: [
    createExtraReducer(createInvoice, (state, action) => {
      state.isLoading = false;
      return state;
    }),
    createExtraReducer(fetchInvoices, (state, action) => {
      state.isLoading = false;
      state.invoicesPaginated = action.payload;
      return state;
    }),
    createExtraReducer(closeOpenTurn, (state, action) => {
      state.invoicesPaginated = {
        data: [],
        total: 0
      };
      return state;
    }),
    createExtraReducer(getInvoiceById, (state, action) => {
      state.isLoading = false;
      state.invoice = action.payload;
      return state;
    }),
  ],
});
