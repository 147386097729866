import React from "react";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import { SaleRecordInfo } from "./components";
import { TextField } from "../../fields/TextField";
import { roundNumberValue } from "../../../utils/number";

const CloseTurnForm = ({ turnSalesDetail, values }) => {
  console.log(values);
  const cashTotal = Number(values.cashTotal || 0);
  const effectiveIncome = Number(values?.effectiveIncome || 0);
  const effectiveExpensive = Number(values?.effectiveExpensive || 0);

  const result =
    turnSalesDetail.base +
    turnSalesDetail.effectiveSales +
    effectiveIncome -
    effectiveExpensive;

  const difference = roundNumberValue(result - cashTotal);

  console.log(values?.effectiveIncome);

  return (
    <div style={{ padding: "1em 1em 2em 1em" }}>
      <div style={{ width: "100%", paddingBottom: "2em" }}>
        <SaleRecordInfo
          label="Efectivo base al iniciar"
          value={turnSalesDetail.base}
        />
        <SaleRecordInfo
          label="Ventas en efectivo"
          value={turnSalesDetail.effectiveSales}
        />
        <SaleRecordInfo
          label="Ventas tarjetas de débito"
          value={turnSalesDetail.debitSales}
        />
        <SaleRecordInfo
          label="Ventas tarjetas de crédito"
          value={turnSalesDetail.creditSales}
        />
        <SaleRecordInfo
          label="Ventas por transferencias"
          value={turnSalesDetail.transferSales}
        />
        <SaleRecordInfo
          label="Devoluciones"
          value={turnSalesDetail.returns}
          style={{ width: "100%", paddingBottom: "2em" }}
        />
        <TextField
          style={{ width: "100%", paddingBottom: "2em" }}
          type="number"
          name="effectiveIncome"
          label="Ingresos de efectivo"
          placeholder="Ingresos de efectivo"
          parse={(value) => Number(value)}
        />
        <TextField
          type="number"
          name="effectiveExpensive"
          label="Retiros de efectivo"
          parse={(value) => Number(value)}
        />
      </div>
      <Divider />
      <TextField
        required
        type="number"
        label="Valor Real En Caja"
        name="cashTotal"
        // format={(value) => "13.24"}
        parse={(value) => Number(value)}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "2em",
        }}
      >
        <Typography>Diferencia</Typography>
        <Typography style={{ fontSize: "20px" }}>${difference}</Typography>
      </div>
      <TextField multiline name="description" label="Observaciones" />
    </div>
  );
};

export default CloseTurnForm;
