import React from 'react';
import { Field } from 'formik';
import { TextField as TextFieldFormik } from 'formik-mui';

const TextField = (props) => (
  <Field
    {...props}
    component={TextFieldFormik}
  />
);

export default TextField;
