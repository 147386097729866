import React, { useState, useCallback, useEffect } from "react";
import { useLocation } from "react-router";
import CircularProgress from "@material-ui/core/CircularProgress";

import { getItem, setItem } from "../../utils/localStorage";
import NotAuth from "./NotAuth";
import useFetchCompanyInfoHook, {
  refreshTokenPost,
} from "./useFetchCompanyInfoHook";

export const AuthContext = React.createContext({
  isAuth: false,
  company: null,
  auth: () => {},
  setCompany: () => {},
});

const useQuery = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};

export const AuthProvider = ({ children }) => {
  const query = useQuery();
  const [isAuth, setAuth] = useState(!!getItem("IS_AUTH"));
  const [angularClientUrl, setAngularClientUrl] = useState(
    getItem("ANGULAR_CLIENT_URL")
  );

  const { loading, company, setState } = useFetchCompanyInfoHook({
    token: query.get("token"),
    setAuth,
    setAngularClientUrl,
  });

  const auth = useCallback((value) => {
    setAuth(value);
    setItem("IS_AUTH", value);
  }, []);

  const onRedirectToAuthHandle = () => {
    window.open(`${angularClientUrl}/admin/invoices`);
  };

  const context = {
    auth,
    company,
    isAuth,
  };

  useEffect(() => {
    if (isAuth) {
      const startRefreshTokenTimer = (token, refreshToken) => {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(token.split(".")[1]));
  
        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - 120 * 1000;
        setTimeout(() => {
          refreshTokenPost({
            client_id: "6fc3d212-6edd-48d8-9dd9-90f8ffa71e7f",
            grant_type: "refresh_token",
            refresh_token: refreshToken,
          }).then((data) => {
            startRefreshTokenTimer(data.accessToken, data.refreshToken);
          }).catch((error) => {
            setState({ company: null, loading: false, error: error });
            setAuth(false);
          });
        }, timeout);
      };
      const token = getItem('TOKEN');
      startRefreshTokenTimer(token, company.refreshToken);
    }
  }, [isAuth, company, setState])


  const content = loading ? (
    <CircularProgress className="loader" />
  ) : isAuth ? (
    children
  ) : (
    <NotAuth onClick={onRedirectToAuthHandle} />
  );

  return <AuthContext.Provider value={context}>{content}</AuthContext.Provider>;
};
