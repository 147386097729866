import { createSlice } from "@reduxjs/toolkit";

import { searchItems } from "./thunks";

export default createSlice({
  name: "items",
  initialState: {
    items: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    cleanError: (state) => {
      state.error = null;
    },
  },
  extraReducers: {
    [searchItems.pending]: (state, action) => {
      state.isLoading = true;
      return state;
    },
    [searchItems.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.items = action.payload;
      return state;
    },
    [searchItems.rejected]: (state, action) => {
      state.isLoading = false;
      return state;
    },
  },
});
