import { createSlice } from "@reduxjs/toolkit";

import { findAllBoxes, findSettingBox, updateBox } from "./thunks";

export default createSlice({
  name: "boxes",
  initialState: {
    boxes: [],
    settingBox: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    cleanError: (state) => {
      state.error = null;
    },
  },
  extraReducers: {
    [findAllBoxes.pending]: (state, action) => {
      state.isLoading = true;
      return state;
    },
    [findAllBoxes.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.boxes = action.payload;
      return state;
    },
    [findAllBoxes.rejected]: (state, action) => {
      state.isLoading = false;
      return state;
    },
    [findSettingBox.pending]: (state, action) => {
      state.isLoading = true;
      return state;
    },
    [findSettingBox.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.settingBox = action.payload;
      return state;
    },
    [findSettingBox.rejected]: (state, action) => {
      state.isLoading = false;
      return state;
    },
    [updateBox.fulfilled]: (state, action) => {
      state.settingBox = action.payload;
      return state;
    },
  },
});
